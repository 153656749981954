import React, { useEffect, useState } from 'react';

const BlobMessage = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <div className={`blob-message ${!visible ? 'fade-out' : ''}`}>
      Try clicking keys!
    </div>
  );
};

export default BlobMessage;
