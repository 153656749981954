import image1 from '../images/project.png';
import image2 from '../images/project (1).png';
import image3 from '../images/project (2).png';
import image4 from '../images/project (3).png';
import image5 from '../images/project (4).png';
import image6 from '../images/project (5).png';
import image7 from '../images/project (6).png';
import image8 from '../images/project (7).png';


const projects = [
    {
        title: "Educational Game: Phil's Laberia",
        description: "An educational game to teach bioengineering lab techniques!",
        links: [
        { label: "GitHub", url: "https://github.com/ngochtran/phils-laberia" },
        { label: "Itch.io", url: "https://knockdev.itch.io/phils-laberia" }
        ],
        tags: ["Unity", "C#"], 
        imageUrl: image2
    },
    {
        title: "Robinson Lab Website",
        description: "A website designed and developed for the Robinson Lab at the Stanford School of Medicine",
        links: [
            { label: "Website", url: "https://robinsonlab.stanford.edu/" }
        ],
        tags: ["React", "Python", "Airtable API"],
        imageUrl: image1
    },
    {
        title: "CS 247G Game: Critter",
        description: "A digital game collaboratively made for CS 247G (Intro to Game Design) about a critter exploring a factory and climate change",
        links: [
            { label: "Itch.io", url: "https://lwcoding.itch.io/critter" }
        ],
        tags: ["Unity", "C#"], 
        imageUrl: image3
    },
    {
        title: "Stanford iGEM Student Org Website",
        description: "A website for the Stanford iGEM VSO (Voluntary Student Organization) at Stanford University, improving visibility for iGEM",
        links: [
            { label: "GitHub", url: "https://github.com/ngochtran/igem-stanford-website" },
            { label: "Website", url: "https://igem.stanford.edu/" },
        ],
        tags: ["React", "Airtable API"], 
        imageUrl: image4
    },
    {
        title: "Palate: a TreeHacks Project",
        description: "A project developed to connect friends and family together through a recipe recommendation algorithm based on personal palate",
        links: [
        { label: "Github", url: "https://github.com/jmcmai/palate" },
        { label: "Devpost", url: "https://devpost.com/software/palate-nozheb" }
        ],
        tags: ["Convex", "React", "Typescript", "Tavliy"], 
        imageUrl: image5
    },
    {
        title: "Heap Allocator",
        description: "Developed malloc, realloc, and free functions in C to manage and fulfill requests for heap memory, maximizing throughput and utilization using coalescing and doubly linked list structures",
        tags: ["C"], 
        imageUrl: image8
    },
    {
        title: "2023 iGEM Competition Wiki",
        description: "The wiki website that documents research done by the Stanford iGEM Team for judging at the Grand Jamboree in Paris, France",
        links: [
        { label: "GitLab", url: "https://gitlab.igem.org/2023/stanford" },
        { label: "Website", url: "https://2023.igem.wiki/stanford/" }
        ],
        tags: ["React"], 
        imageUrl: image6
    }, 
    {
        title: "Game Jam: Curfew",
        description: "A game made during the 2023 Fireside Game Jam about a returning a lost child home",
        links: [
        { label: "GitHub", url: "https://github.com/ngochtran/Curfew2D" },
        { label: "Itch.io", url: "https://knockdev.itch.io/curfew" }
        ],
        tags: ["Unity", "C#"], 
        imageUrl: image7
    }
];

export default projects;
