const technologies = [
    { name: 'Python', imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Python_logo_01.svg/2048px-Python_logo_01.svg.png' },
    { name: 'C', imageUrl: 'https://cdn.iconscout.com/icon/free/png-256/free-c-58-1175247.png' },
    { name: 'C++', imageUrl: 'https://www.stevenlexr.com/assets/cpp-18ed9ede.png' },
    { name: 'C#', imageUrl: 'https://www.stevenlexr.com/assets/csharp-e97c7d1f.png' },
    { name: 'Java', imageUrl: 'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/181_Java_logo_logos-512.png' },
    { name: 'Typescript', imageUrl: 'https://cdn-icons-png.flaticon.com/512/919/919832.png' },
    { name: 'React', imageUrl: 'https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png' },
    { name: 'Unity', imageUrl: 'https://www.svgrepo.com/show/333618/unity.svg' },
    { name: 'HTML', imageUrl: 'https://www.stevenlexr.com/assets/html-92b76a73.png' },
    { name: 'CSS', imageUrl: 'https://www.stevenlexr.com/assets/css-79a7f026.png' },
    { name: 'Javascript', imageUrl: 'https://static.vecteezy.com/system/resources/previews/027/127/463/original/javascript-logo-javascript-icon-transparent-free-png.png' },
    { name: 'NodeJS', imageUrl: 'https://www.svgrepo.com/show/376337/node-js.svg' },
    { name: 'SQL', imageUrl: 'https://symbols.getvecta.com/stencil_28/61_sql-database-generic.90b41636a8.png' },
  ];
  
  export default technologies;