import React, { useState, useEffect } from 'react';
import Spline from '@splinetool/react-spline';
import { Analytics } from "@vercel/analytics/react"
import './App.css';

import BlobMessage from './BlobMessage';

// data 
import technologies from './data/Technologies';
import projects from './data/Projects';
import achievements from './data/Achievements';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

// images & media
import stanfordPixel from './images/stanford.png'
import resume from './images/ngoc_tran_resume.pdf'


export default function App() {
  const [currentWord, setCurrentWord] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const currentIndexRef = React.useRef(0);
  const currentWordIndexRef = React.useRef(0);
  const [showNavbar, setShowNavbar] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  const openResume = () => {
    window.open(resume, '_blank');
  };

  useEffect(() => {
    const words = ['an Aspiring Software Dev.', 'a Designer.', 'an Artist.']; 
    let interval;

    const typeAndDeleteWord = () => {
      const wordToAnimate = words[currentWordIndexRef.current];
      const currentIndex = currentIndexRef.current;

      if (isTyping) {
        setCurrentWord(wordToAnimate.substring(0, currentIndex) + "|");
        currentIndexRef.current = currentIndex + 1;

        if (currentIndex >= wordToAnimate.length) {
          setCurrentWord(wordToAnimate);
          setTimeout(() => {
            setIsTyping(false);
          }, 500);
        }
      } else {
        setCurrentWord(wordToAnimate.substring(0, currentIndex) + "|");
        currentIndexRef.current = currentIndex - 1;

        if (currentIndex <= 0) {
          currentWordIndexRef.current++;
          if (currentWordIndexRef.current >= words.length) {
            currentWordIndexRef.current = 0;
          }
          setIsTyping(true);
        }
      }
    };

    interval = setInterval(typeAndDeleteWord, 100);

    return () => {
      clearInterval(interval);
    };
  }, [isTyping]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 1.5) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const navLinks = document.querySelectorAll('.navigation a');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute('id');
            navLinks.forEach((link) => {
              link.classList.toggle('active', link.getAttribute('href') === `#${id}`);
            });
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.7,
      }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      return (prevIndex + visibleCount) % projects.length;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      return (prevIndex - visibleCount + projects.length) % projects.length;
    });
  };

  useEffect(() => {
    const updateVisibleCount = () => {
      if (window.innerWidth <= 1000) {
        setVisibleCount(1);
      } else {
        setVisibleCount(3);
      }
    };

    updateVisibleCount(); // Initial call
    window.addEventListener('resize', updateVisibleCount); // Update on resize

    return () => {
      window.removeEventListener('resize', updateVisibleCount);
    };
  }, []);

  const getProjectsForDisplay = () => {
    const startIndex = Math.floor(currentIndex / visibleCount) * visibleCount;
    const endIndex = Math.min(startIndex + visibleCount, projects.length);

    return projects.slice(startIndex, endIndex);
  };

  const displayedProjects = getProjectsForDisplay();

  return (
    <div>
      <Analytics/>
      <BlobMessage/>
      {/*NAVIGATION*/}
      <ul className={`navigation ${showNavbar ? 'visible' : ''}`}>
        <li><a href="#home">Home</a></li>
        <li><a href="#about-me">About Me</a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#achievements">Achievements</a></li>
        <li><a href="#contact-me">Contact Me</a></li>
      </ul>

      {/*HOME SECTION*/}
      <section id="home">
        <section className="column-section">
          <div className="section-block spline">
            <Spline className="hi" scene="https://prod.spline.design/MnsTL496SGiPnZyi/scene.splinecode" />
          </div>
          <div className="section-block vert-center">
            <h1>Hello! I'm <span className="accent bayon">Ngoc</span>,</h1>
            <h2 className="lato">{currentWord}</h2>
            <div>
              <button onClick={openResume} className="resume-button">
                View Resume
              </button>
            </div>
            <ul id="footer-links" className="flex-row-1">
              <li className="nav-item-icon">
                <a className="nav-link main-color active" href="mailto:nhtran@stanford.edu" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </li>
              <li className="nav-item-icon">
                <a className="nav-link main-color active" href="https://www.linkedin.com/in/ngoc-h-tran" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
              <li className="nav-item-icon">
                <a className="nav-link main-color active" href="https://github.com/ngochtran" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </section>

      {/*ABOUT ME SECTION*/}
      <section id="about-me">
        <div className="my-info"> 
          <h2>About Me</h2>
          <h4>NGOC TRAN | STANFORD '26 | CS — HCI </h4>
          <p>
            Currently, I am a junior at Stanford University, studying Computer Science on the Human-Computer Interaction track! 
            I am particularly interested in game design, web development, and bridging interdisciplinary fields to create innovative solutions. 
            My passion lies in combining technical expertise with creative design to build impactful projects.
          </p>
          <h3>Technologies — languages & tools</h3>
          <div className="technologies">
            {technologies.map((tech, index) => (
              <div key={index} className="technology-circle">
                <img src={tech.imageUrl} alt={tech.name} />
                <p>{tech.name}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* PROJECTS SECTION */}
      <section id="projects">
        <div className="my-info">
          <h2>Projects</h2>
          <div className="carousel-container">
          <button className="carousel-button prev" onClick={handlePrev}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
            <div className="carousel-content">
              {displayedProjects.map((project, index) => (
                <div key={index} className="project-card">
                  {project.imageUrl && <img src={project.imageUrl} alt={project.title} />}
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <div className="project-links">
                    {project.links && project.links.map((link, linkIndex) => (
                      <a
                        key={linkIndex}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="project-button"
                      >
                        {link.label}
                      </a>
                    ))}
                  </div>
                  <div className="project-tags">
                    {project.tags && project.tags.map((tag, tagIndex) => (
                      <span key={tagIndex} className="project-tag">{tag}</span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
              <button className="carousel-button next" onClick={handleNext}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </section>

      {/*ACHIEVEMENTS SECTION*/}
      <section id="achievements">
      <div className="my-info">
        <h2>Leadership & Achievements</h2>
        <div className="achievement-cards">
          {achievements.map((achievement, index) => (
            <div key={index} className="achievement-card">
              <h3>{achievement.title} ►</h3>
              <p>{achievement.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

      {/*CONTACT ME SECTION*/}
      <section id="contact-me">
        <div className="my-info">
          <footer>
            <img src={stanfordPixel} alt="pixel art of Stanford Hoover Tower" />
            <div className="top-spacer footer">
              <h2 className="footer-title bayon">Let's Connect!</h2>
              <p className="spacer lato center">email: nhtran@stanford.edu</p>
              <ul id="footer-links" className="flex-row">
                <li className="nav-item-icon">
                  <a className="nav-link main-color active" href="mailto:nhtran@stanford.edu" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
                <li className="nav-item-icon">
                  <a className="nav-link main-color active" href="https://www.linkedin.com/in/ngoc-h-tran" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li className="nav-item-icon">
                  <a className="nav-link main-color active" href="https://github.com/ngochtran" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
}
