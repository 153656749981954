const achievements = [
    {
        title: "Code in Place Section Leader",
        description: "Led weekly tutorial sections about Python for a group of students in Stanford’s Code in Place program",
    },
    {
        title: "Stanford Vietnamese Student Association (SVSA) Chair",
        description: "Leading SVSA from 2022-2024 in the Finance, PR, and ACE (Sib-Fam) committees",
    },
    {
        title: "Stanford iGEM Vice-President",
        description: "Co-founded the iGEM VSO, increasing and securing funds as a student organization",
    },
    {
        title: "CHEMENG12SC Sophomore College Course Assistant (SCA)",
        description: "Facilitated and organized the CHEMENG12SC course during the 2024 rendition",
    },
    {
        title: "Gates Scholar",
        description: "Issued by the Bill and Melinda Gates Foundation",
    },
];

export default achievements;
